.signup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
}

.signup_container {
  display: flex;
  height: 100%;
}

.signup_container_left {
  flex: 4;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.signup_container_right {
  flex: 6;
  height: 80vh;
}

.signup_container h2 {
  padding: 30px 0px;
  color: #e83e8c;
}

.form_signup label {
  color: black;
  padding: 5px 0px;
  font-family: arial;
}

.form_signup input {
  border: 2px solid #2f9bfe;
  outline: none;
  padding: 7px;
  border-radius: 5px;
  min-width: 300px;
}

.btn_signup_signin button {
  background-color: #0faf51;
  color: #fff;
  padding: 10px 30px;
}

.login_signin a,
.losspass a {
  text-decoration: none;
  color: #0166d6;
}

.login a:hover,
.losspass a:hover {
  color: #0faf51;
}

.signup_container_right {
  background-repeat: no-repeat;
  background-size: contain;
}

.losspass {
  margin-top: 7px !important;
}

.input_sign.input_phone,
.input_sign.input_password {
  margin-top: 10px;
}

.logo_login {
    width: 300px;
}

@media only screen and (max-width: 920px) {
  .signup_container_right {
    display: none;
  }

  .logo_login {
    width: 70vw !important;
  }

  .signup {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-direction: column;
    padding: 40px 0px;
  }

  ._1200px {
    width: 100% !important;
    height: 100% !important;
  }

  .signup_container_left {
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .form_signup{
    width: 80vw !important;
  }
  
}
