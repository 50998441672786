.gift-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gift-box {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  text-align: center;
}

.gift-box:hover {
  transform: scale(1.1);
}

.gift-box-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.click-to-open {
  font-size: 1.2rem;
  margin-top: 10px;
  color: rgb(0, 123, 255);
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

.opened-box {
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.confetti-image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.congratulations-text {
  font-size: 2rem;
  color: rgb(0, 123, 255);
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}

.reward-text {
  font-size: 1rem;
  color: rgb(220, 53, 69);
}
