.shop_user {
  display: flex;
  justify-content: center;
}

.shop_user_image {
  margin: 0px 5px;
  cursor: pointer;
  padding: 30px 0px;
  height: 100% !important;
  width: auto;
}

.fish_notify {
  border: 2px dashed rgb(23, 162, 184);
  padding: 10px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-wrap: wrap;
  text-align: center;
  line-height: 22px;
}

.fish_action_btn_list{
    display: flex;
    position: absolute;
    bottom: 40px;
    right: 25px;
}

.username_top{
    font-size: 8px;
    margin: 0;
    padding: 0;
    color: yellow;
    margin-left: 2px;
}
