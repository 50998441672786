/* css header and logo */
header {
  /* background-color: var(--primary); */
  background-color: #fff;
}

.logo a:hover {
  color: #fff !important;
}

.logo_title {
  color: blue;
}

.input_keyword {
  border: 1px solid grey !important;
}

._1200px {
  /* width: 1000px; */
  width: 1200px;
}

.nav_left {
  display: flex;
  align-items: center;
}

.logo img {
  width: 200px;
  height: auto !important;
}

.logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: var(--white);
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.search {
  padding-left: 100px;
  display: flex;
  align-items: center;
}

.search input {
  font-size: 1rem;
  outline: none;
  border: none;
  min-width: 250px;
}

.btn_search,
.search input {
  padding: 8px;
  border-radius: 0.25rem;
}

.btn_search {
  background-color: var(--red);
  padding: 8px 15px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  outline: none;
}

.btn_search .fa-search {
  color: var(--white);
  cursor: pointer;
}

.nav_right .fa-user,
.nav_right .fa-shopping-cart {
  color: rgb(33, 150, 243);
}

.nav_right .fa-user,
.nav_right .fa-shopping-cart {
  font-size: 20px;
}

.nav_right a:nth-child(2) {
  margin-left: 20px;
}

.fa-user:hover,
.fa-shopping-cart:hover {
  color: #ffeb3b;
}

.btn_search:hover {
  background-color: #62b0fd;
}