/* NotFound.css */
._404_page {
  background-color: #f0f4f8;
  width: 100vw;
}
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
}

.not-found-content {
  max-width: 600px;
}

.not-found-title {
  font-size: 96px;
  color: #e74c3c;
  margin: 0;
}

.not-found-subtitle {
  font-size: 36px;
  color: #34495e;
}

.not-found-message {
  font-size: 18px;
  color: #7f8c8d;
  margin: 10px 0;
}

.not-found-image {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.not-found-links {
  margin-top: 20px;
}

.not-found-link {
  display: inline-block;
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  color: white !important;
  background-color: #3498db;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.not-found-link:hover {
  background-color: #2980b9;
}
