body {
  line-height: 1;
  background-color: #eeeeee;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* * {
  font-family: Arial, Helvetica, sans-serif;
} */

/* CSS APP */

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.app_container {
  width: 30vw;
  padding: 10px;
  background: #eaf7ff;
}

.text_hello {
  margin: 12px 0px !important;
  color: green;
}

.text_hello b {
  font-weight: bold;
}

.list_app .one_app {
  background-color: #fff;
  padding: 15px !important;
  border-radius: 5px;
}

.list_app .one_app:hover {
  box-shadow: 0 4px 8px #62b0fd, 0 4px 12px rgb(0 0 0 / 8%);
  transition: all linear;
}

.list_app .one_app .one_app--top {
  display: flex;
}

.list_app .one_app .one_app--top .one_app--top_right {
  margin-left: 10px !important;
}

.list_app .one_app .one_app--top .one_app--top_left {
  width: 80px;
  height: 80px;
}

.one_app--top_right .app_title_name {
  font-weight: bold;
  margin-left: 5px !important;
}

.one_app--top_right .app_price .one_app_child_title {
  font-weight: bold;
  margin-top: 15px !important;
  margin-right: 5px !important;
  display: inline-block;
}

.app_download {
  display: inline-block;
  background-color: #dc3545;
  padding: 5px 15px !important;
  cursor: pointer;
  margin-top: 15px !important;
  border-radius: 3px !important;
  color: #fff;
}

.app_download a {
  text-decoration: none;
  color: #fff;
}

.app_download:hover {
  background-color: #007bff;
}

.app_get_code {
  display: inline-block;
  background-color: #07bc0c;
  padding: 5px 15px !important;
  cursor: pointer;
  border-radius: 3px !important;
  margin-left: 5px;
}

.app_get_code a {
  text-decoration: none;
  color: #fff;
}

.app_get_code:hover {
  background-color: #007bff;
}

.app_check_code {
  text-align: center;
  margin-top: 15px !important;
}
.app_check_code span {
  background-color: #007bff;
  padding: 5px 15px !important;
  cursor: pointer;
  border-radius: 3px !important;
  margin-left: 5px;
  color: #fff;
}

.app_check_code span:hover {
  background-color: #dc3545;
}

.app_download_error {
  background-color: #ffc107;
  margin-top: 15px !important;
  line-height: 20px;
  font-weight: 500;
  color: #000;
  padding: 5px;
  border-radius: 5px;
}

.one_app--bottom .one_app--bottom-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px !important;
}

.one_app--bottom .icon_down {
  width: 16px !important;
  height: 16px !important;
  margin-right: 5px;
}

.one_app--bottom .one_app--bottom_step {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.one_app--bottom .one_app--bottom_step:hover {
  color: #007bff;
}

.one_app--bottom_step_title {
  font-weight: bold !important;
}

.one_app--bottom-bottom ol,
.one_app--bottom-bottom ul {
  margin-top: 20px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.one_app--bottom-bottom li {
  margin: 10px 0px !important;
  line-height: 25px !important;
}

.app_note {
  margin-top: 20px !important;
}

.app_note .app_note--title,
.app_video .app_video--title {
  font-weight: bold !important;
  color: #17a2b8 !important;
}

.app_note .app_note--content {
  margin-top: 10px;
  line-height: 20px;
}

.app_video {
  margin-top: 15px !important;
}

.app_video .video_source {
  margin-top: 10px !important;
}

.one_app_child_content {
  line-height: 20px;
}

.mau_sac {
  color: rgb(40, 167, 69);
}

.one_app--bottom_step_title {
  margin-bottom: 0px;
}

.input_username_container {
  display: flex;
  align-items: center;
  width: 100%;
}

.input_username_app {
  padding: 5px;
  border-radius: 5px;
  border: 1px dashed rgb(0, 123, 255);
  outline: none;
  width: 55%;
}

.btn_create_username {
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 5px;
}

.btn_create_username-content {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.Toastify div {
  line-height: 22px !important;
}

.style-15::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.style-15::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.style-15::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    40% 0%,
    75% 84%,
    from(#4d9c41),
    to(#19911d),
    color-stop(0.6, #54de5d)
  );
}

span.one_image {
  position: relative;
}

span.remove_image {
  color: red;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: 0px;
}
.mg_15 {
  margin-top: 15px;
}

.list_name {
  text-align: center;
  background-color: #3883cc;
  color: #fff;
  font-size: 17px;
  padding: 10px 0px;
  border-radius: 5px 5px 0px 0px;
}

.list_name_no_border {
  text-align: center;
  background-color: #3883cc;
  color: #fff;
  font-size: 17px;
  padding: 10px 0px;
}

p {
  line-height: 25px;
}

/* CSS MOBILE */
@media only screen and (max-width: 800px) {
  .app_container {
    width: 100vw;
  }

  .menu_side {
    position: relative;
  }

  .icon_bars {
    position: fixed;
    top: 10px;
    left: 10px;
    cursor: pointer;
    z-index: 1000;
    font-size: 25px;
    display: block !important;
  }

  .sidebar-wrapper {
    bottom: 0;
    left: -300px;
    position: fixed;
    z-index: 500;
  }

  .sidebar_user_active {
    animation: sidebar_user_on 1s ease-out !important;
    left: 0;
  }

  .sidebar_user_none_active {
    animation: sidebar_user_off 1s ease-out !important;
    left: -300px !important;
  }

  @keyframes sidebar_user_off {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-300px);
    }
  }

  @keyframes sidebar_user_on {
    0% {
      transform: translateX(-300px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  @keyframes background_user_off {
    0% {
      transform: translateX(300px);
      left: 300;
      right: -300;
    }
    100% {
      transform: translateX(0px);
      inset: 0;
    }
  }

  @keyframes background_user_on {
    0% {
      transform: translateX(0px);
      inset: 0;
    }
    100% {
      transform: translateX(300px);
      left: 300;
      right: -300;
    }
  }

  .card-body.user_account .col-6 {
    min-width: 100%;
  }

  .background_on {
    position: fixed;
    z-index: 100;
    opacity: 0.4;
    background-color: rgb(0, 0, 0);
    animation: background_user_on 1s ease-out !important;
    width: 100vw;
    height: 100vh;
    inset: 0;
    display: block;
  }

  .background_off {
    position: fixed;
    z-index: 100;
    opacity: 0.4;
    background-color: rgb(0, 0, 0);
    width: 100vw;
    height: 100vh;
    inset: 0;
    animation: background_user_off 0.2s ease-out !important;
    display: none;
  }

  .Toastify .Toastify__toast-container {
    margin: 10px !important;
    width: 90% !important;
  }

  #main {
    width: 100vw !important;
    margin: 0px 5px;
    padding: 0px 10px;
  }

  .add_edit_class {
    min-width: 90vw !important;
  }

  .mobile_min_width_100 {
    min-width: 100%;
    margin: 3px 0px !important;
    padding: 2px 5px !important;
  }

  .mobile_min_width_100_vfix {
    min-width: 100%;
    margin: 0px !important;
    padding: 0px 25px 0px 25px !important;
  }

  .mobile_no_wrap {
    flex-wrap: nowrap;
  }

  .sroll_form {
    flex-direction: column !important;
    max-height: 55vh;
  }

  .mobile_pd_mg_reset {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mobile_action_top_controll {
    display: flex;
    flex-direction: column;
  }

  .mobile_mg5_0 {
    margin: 5px 0px;
  }

  .mobile_min_width_100px {
    min-width: 100px;
  }

  /* .confirm_container {
    width: auto !important;
  } */
  .confirm_container {
    width: 60vw !important;
  }

  .page_withdraw .col-9 {
    min-width: 100% !important;
  }

  .one_gift {
    width: 80% !important;
  }

  .one_gift img {
    width: 95% !important;
  }
}
/* .sidebar-wrapper {
  left: 0;
} */
.confirm_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_bars {
  display: none;
}

.end_list {
  text-align: center;
  margin-top: 10px;
  color: #dc3545;
  font-weight: bold;
}

.gift_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.one_gift {
  margin: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #eee;
}

.one_gift img {
  width: 200px;
  border-radius: 5px;
}

.one_gift .gift_name {
  font-weight: bold;
  margin-top: 5px;
}

.gift_list .method_activd {
  border: 2px solid #007bff;
}

.page-content section.row {
  margin-right: -10px;
  margin-left: -10px;
}

.pagination_mg {
  margin-top: 10px;
}
.pagination_mg div {
  text-align: center;
  line-height: 20px;
}

.app_pending {
  padding: 2px 10px;
  background: #007bff;
  color: #fff;
  border-radius: 5px;
  text-wrap: nowrap;
}

.app_approved {
  padding: 2px 10px;
  background: rgb(40, 167, 69);
  color: #fff;
  border-radius: 5px;
  text-wrap: nowrap;
}

.app_after_pending {
  padding: 2px 10px;
  background: #ffc107;
  color: #fff;
  border-radius: 5px;
  text-wrap: nowrap;
}

.app_reject {
  padding: 2px 10px;
  background: red;
  color: #fff;
  border-radius: 5px;
  text-wrap: nowrap;
}

.ref_friend {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
  line-height: 22px;
}

.ref_detail {
  background-color: #eaf6fc;
  border-radius: 5px;
  border: 1px solid #bce8f1;
  padding: 10px;
  color: #31708f;
}

.one_bxh_child_title {
  margin-top: 15px !important;
  margin-right: 5px !important;
  display: inline-block;
}

.one_bxh_child_title_v2 {
  margin-right: 5px !important;
  display: inline-block;
}
