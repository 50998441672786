.chat_container {
  width: 35vw;
  border-radius: 5px;
  background: #eaf7ff;
  padding: 10px;
  font-family: vuivl !important;
  min-height: 100vh;
}

.chat_name {
  text-align: center;
  background-color: #3883cc;
  color: #fff;
  font-size: 20px;
  padding: 10px 0px;
  border-radius: 5px 5px 0px 0px;
}

.chat_content textarea {
  width: 100%;
  height: auto;
  padding: 6px 5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  border-left: 2px solid #44b6ae !important;
  font-family: vuivl;
}

.chat_content .btn_sent {
  background-color: #4cae4c;
  color: #fff;
  padding: 4px 15px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: vuivl;
}

.chat_content .btn_delete_chat {
  background-color: #007bff;
  color: #fff;
  padding: 4px 15px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px;
  margin-top: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: vuivl;
  margin-left: 10px;
}

.chat_input {
  background-color: #eaf6fc;
  border-radius: 5px;
  border: 1px solid #bce8f1;
  color: #31708f;
  margin: 5px 0;
  padding: 10px;
}

.chat_list {
  margin-top: 10px;
  max-height: 70vh;
  overflow: auto;
}

.txt_red {
  color: rgb(220, 53, 69);
}

.txt_blue {
  color: #007bff;
}

.chat_list .one_message {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.chat_list .one_message .user_info {
  flex: 3;
}

.chat_list .one_message .message_user {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  flex: 7;
  margin: 0px 10px;
}

.chat_list .one_message .message_user .user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat_list .one_message .message_user .user_info .user_name {
  margin-left: 5px;
  color: rgb(255, 0, 0);
  font-size: 16px;
}

.chat_list .one_message .message_user .user_message {
  margin: 5px 0px;
  border-top: 1px solid #e0e0e0;
  padding-top: 2px;
  font-size: 14px;
  line-height: 22px;
}

.chat_list .one_message .message_user .message_time {
  font-size: 13px;
  line-height: 22px;
  text-align: right;
  margin-right: 10px;
  color: #777;
}

@media only screen and (max-width: 800px) {
  .chat_container {
    width: 99vw;
  }
}
